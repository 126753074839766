import React from "react"
import { graphql } from "gatsby"
import style from "./cta.mod.scss"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { useLocation } from "@reach/router"

const Cta = ({ heading, subHeading, image, link, backLink, overlay }) => {
  return (
    <CtaWrapper link={link} backLink={backLink}>
      <div className={style.cta__wrapper}>
        <div className={style.cta__overlay}>{overlay}</div>
        {image && (
          <GatsbyImage
            className={style.cta__image}
            image={image.gatsbyImageData}
          />
        )}
        <div className={style.cta__content}>
          {subHeading && <p className={style.cta__subHeading}>{subHeading}</p>}
          <p className={style.cta__heading}>{heading}</p>
        </div>
      </div>
    </CtaWrapper>
  )
}

const CtaWrapper = ({ children, link, backLink }) => {
  const { pathname } = useLocation()

  return link ? (
    <Link
      className={style.cta}
      title={link.title}
      to={link.path}
      state={(backLink, { referrer: pathname })}
    >
      {children}
    </Link>
  ) : (
    <div className={style.cta}>{children}</div>
  )
}

export default Cta

Cta.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  image: PropTypes.object.isRequired,
  link: PropTypes.shape({
    title: PropTypes.string,
    path: PropTypes.string
  }),
  backLink: PropTypes.shape({
    title: PropTypes.string,
    path: PropTypes.string
  })
}

export const query = graphql`
  fragment Cta on DatoCmsCta {
    model {
      apiKey
    }
    id
    heading
    subHeading
    image {
      gatsbyImageData(imgixParams: { fit: "crop", w: "770", h: "460" })
    }
    link {
      ...LinkHelper
    }
  }

  fragment ChallengeCta on DatoCmsChallenge {
    id
    slug
    title
    startDate
    date: startDate(formatString: "dddd do MMM")
    duration
    anytimeChallenge
    listId
    banner {
      gatsbyImageData(imgixParams: { w: "770", h: "460", fit: "crop" })
    }
    path
    internal {
      type
    }
    meta {
      firstPublishedAt
    }
  }

  fragment ChallengeGroupCta on DatoCmsChallengeGroup {
    id
    slug
    title
    startDate
    date: startDate(formatString: "dddd do MMM")
    duration
    anytimeChallenge
    banner {
      gatsbyImageData(imgixParams: { w: "770", h: "460", fit: "crop" })
    }
    path
    internal {
      type
    }
    meta {
      firstPublishedAt
    }
  }
`
