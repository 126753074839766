import { formatDate, formatChallengeDate } from "@helpers/dateHelper"
import { getImageFromMedia } from "@helpers/workoutHelper"

const vlogItemConfig = (node, isFav, toggleFav) => ({
  link: {
    title: node.title,
    path: node.path
  },
  title: node.title,
  subHeading: formatDate(node.meta.publishedAt),
  // TODO: add back in once favs working
  // image: node.chosenThumbnail
  //   ? node.chosenThumbnail.fluid
  //   : node.thumbnailUrl.childImageSharp.fluid,
  path: node.path,
  id: node.id,
  slug: node.slug,
  isFav: isFav,
  toggleFav: toggleFav
})

const workoutItemConfig = node => ({
  link: {
    title: node.title,
    path: node.path
  },
  title: node.title,
  subHeading: node.category.title,
  image: getImageFromMedia(node.media[0]),
  path: node.path,
  id: node.id,
  slug: node.slug,
  equipment: node.content[0].items,
  icons: [
    {
      type: "cook",
      content: `${node.duration} min`
    },
    {
      type: "drop",
      content: node.defaultRating
    }
  ]
})

const runningPlanCtaConfig = runningPlan => ({
  heading: runningPlan.title,
  subHeading: `${runningPlan.distance} KM`,
  image: runningPlan.banner,
  link: {
    title: runningPlan.title,
    path: runningPlan.path
  },
  id: runningPlan.id
})

const dealCtaConfig = deal => ({
  heading: deal.title,
  subHeading: deal.code ? `Use CODE: ${deal.code}` : "",
  image: deal.image,
  link: {
    title: deal.title,
    path: deal.path
  },
  id: deal.id
})

const challengeCtaConfig = challenge => ({
  heading: challenge.title,
  subHeading: challenge.startDate
    ? `Starts - ${formatChallengeDate(challenge.startDate)}`
    : "Do Anytime",
  image: challenge.banner,
  link: {
    title: challenge.title,
    path: challenge.path
  },
  id: challenge.id
})

const recipeItemConfig = (
  { title, id, path, heroImage, prepTime, cookTime, dietaryTypes },
  isFav,
  toggleFav
) => ({
  title,
  id,
  image: heroImage,
  link: {
    title,
    path
  },
  badges: dietaryTypes.map(item => ({
    svg: item.badge.url,
    label: item.name
  })),
  icons: [
    {
      type: "prep",
      content: `${prepTime}`,
      subText: "prep"
    },
    {
      type: "cook",
      content: `${cookTime}`,
      subText: "cook"
    }
  ],
  isFav: isFav,
  toggleFav: toggleFav
})

const recipeCategoryConfig = ({ name, namePlural, path, id, image }) => ({
  title: namePlural ? namePlural : name,
  link: { title: namePlural, path: path },
  id: id,
  image: image
})

export {
  vlogItemConfig,
  dealCtaConfig,
  runningPlanCtaConfig,
  challengeCtaConfig,
  workoutItemConfig,
  recipeItemConfig,
  recipeCategoryConfig
}
