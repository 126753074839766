import React, { useEffect, useState } from "react"
import { ProtectedRoute } from "../components/ProtectedRoute"
import Content from "@elements/Content"
import Seo from "@elements/Seo"
import Block from "@global/layout/Block"
import Layout from "@layouts/layout"
import Button from "@global/elements/Button"
import { challengeCtaConfig } from "@helpers/listHelper"
import { isFuture, isPast } from "date-fns"
import { getChallengeEndDate } from "@helpers/dateHelper"
import styledTabs from "../components/newTab.mod.scss"
import Registered from "@svgs/registered.svg"
import { useChallenge } from "../context/ChallengeContext/ChallengeState"
import { useAuth0 } from "@utils/auth"
import CardsWrapper from "../components/global/cards/CardsWrapper"
import Cta from "@global/cards/Cta"
import Banner from "@global/layout/Banner"
import { graphql } from "gatsby"
import { format } from "date-fns"

const ChallengesPage = ({ data, pageContext: { title, parent } }) => {
  const {
    autoPilotLists,
    getUserContactLists,
    hasBeenChecked,
    loadingChallenge
  } = useChallenge()
  const { challengesBanner } = data.banner
  const [doAnytime, setDoAnytime] = useState([])
  const [current, setCurrent] = useState([])
  const [comingSoon, setComingSoon] = useState([])
  const { loading, user } = useAuth0()
  const [challengesList, setChallengesList] = useState([])

  useEffect(() => {
    if (user && !hasBeenChecked) {
      getUserContactLists(user?.email)
    }
  }, [user])

  useEffect(() => {
    const challenges = data.challenges.nodes.filter(item => {
      return !item.group
    })
    setChallengesList([...challenges, ...data.challengesGroups.nodes])
  }, [])

  useEffect(() => {
    const doAnytimeChallenges = challengesList.filter(
      challenge => challenge.anytimeChallenge === true
    )
    setDoAnytime(doAnytimeChallenges)

    const currentChallenges = challengesList.filter(
      challenge =>
        isFuture(
          new Date(getChallengeEndDate(challenge.startDate, challenge.duration))
        ) && isPast(new Date(challenge.startDate))
    )
    setCurrent(currentChallenges)

    const comingSoonChallenges = challengesList.filter(challenge =>
      isFuture(new Date(challenge.startDate))
    )
    setComingSoon(comingSoonChallenges)
  }, [challengesList])

  const checkIfUserIsRegistered = listId => {
    return autoPilotLists.includes(listId)
  }

  const tabLabels = ["do anytime", "current", "starting soon"]

  const { challengesButton, challengesText } = data.miscContent

  const buttonHasLink = challengesButton[0]?.link?.link !== null

  const challengesButtonLink = buttonHasLink
    ? challengesButton[0]?.link.link.path
    : challengesButton[0]?.link.url

  return (
    <Layout title={title} parent={parent}>
      <Seo
        title={data.content.challenges.meta.title}
        description={data.content.challenges.meta.desc}
        image={challengesBanner.url}
      />
      <ProtectedRoute>
        <Banner image={challengesBanner} hiddenSmall={true} />
        <ChallengesPage_Content>
          <Block padding="double-top" gutters={true} maxWidth="inner">
            <Content html={challengesText} />
            {challengesButton.length > 0 && (
              <Block padding="top">
                <Button
                  to={challengesButtonLink}
                  text={challengesButton[0].link.displayText}
                />
              </Block>
            )}
          </Block>
        </ChallengesPage_Content>
        <Block padding="both">
          <TabsComponent
            checkIfUserIsRegistered={checkIfUserIsRegistered}
            doAnytime={doAnytime}
            current={current}
            comingSoon={comingSoon}
            challengesList={challengesList}
            tabLabels={tabLabels}
          />
        </Block>
      </ProtectedRoute>
    </Layout>
  )
}

export default ChallengesPage

export const TabsComponent = ({
  current,
  doAnytime,
  comingSoon,
  checkIfUserIsRegistered,
  tabLabels,
  defaultTab = 0
}) => {
  const [currentTab, setCurrentTab] = useState(defaultTab)
  const AllContents = [[...doAnytime], [...current], [...comingSoon]]

  return (
    <Block padding="double-top" gutters={true} maxWidth="list-inner">
      <WrapperTab>
        {tabLabels.map((tab, tabIndex) => (
          <TabLabel
            key={tabIndex}
            currentTab={currentTab}
            tabIndex={tabIndex}
            onClick={() => setCurrentTab(tabIndex)}
          >
            {tab}
          </TabLabel>
        ))}
      </WrapperTab>
      {AllContents.map((challenge, contentIndex) => {
        return (
          <React.Fragment key={`challenges-${contentIndex}`}>
            {contentIndex === currentTab && (
              <CardsWrapper key={`challenges}`} modifier={"ctas"}>
                {challenge.length > 0 ? (
                  <>
                    {challenge.map(item => {
                      return (
                        <li key={item.id}>
                          <Cta
                            {...challengeCtaConfig(item)}
                            subHeading={
                              item.anytimeChallenge
                                ? "Do Anytime"
                                : `Starts - ${format(
                                    new Date(item.startDate),
                                    "EEEE  do MMM"
                                  )}`
                            }
                            startDate={item.startDate}
                            overlay={
                              checkIfUserIsRegistered(item?.listId) && (
                                <Registered />
                              )
                            }
                          />
                        </li>
                      )
                    })}
                  </>
                ) : (
                  <Block gutters={true}>
                    <p style={{ position: "absolute", left: "45%" }}>
                      No Challenges
                    </p>
                  </Block>
                )}
              </CardsWrapper>
            )}
          </React.Fragment>
        )
      })}
    </Block>
  )
}

export const WrapperTab = ({ children }) => {
  return (
    <div className={styledTabs.tabs}>
      <ul className={styledTabs.tabs__list}>{children}</ul>
      <div className={styledTabs.tabs__progress}></div>
    </div>
  )
}

export const TabLabel = ({ children, onClick, currentTab, tabIndex }) => {
  return (
    <li
      className={styledTabs.tabs__list_item}
      data-is-current={currentTab === tabIndex}
    >
      <button onClick={onClick} className={styledTabs.tabs__button}>
        {children}
      </button>
    </li>
  )
}

export const TabPanel = ({ children }) => {
  return <>{children}</>
}

export const ChallengesPage_Content = ({ children }) => {
  return <>{children}</>
}

export const CTAWrapper = ({ children }) => {
  return <>{children}</>
}

export const query = graphql`
  query ChallengeQuery($language: String, $locale: String) {
    challenges: allDatoCmsChallenge(
      filter: { locale: { eq: $language }, country: { in: [$locale, "Both"] } }
    ) {
      nodes {
        country
        ...ChallengeCta
        group {
          id
          slug
        }
      }
    }
    challengesGroups: allDatoCmsChallengeGroup(
      filter: { locale: { eq: $language }, country: { in: [$locale, "Both"] } }
    ) {
      nodes {
        id
        slug
        title
        startDate(formatString: "dddd do MMM")
        duration
        anytimeChallenge
        banner {
          gatsbyImageData(imgixParams: { w: "770", h: "460", fit: "crop" })
        }
        path
        internal {
          type
        }
        meta {
          firstPublishedAt
        }
      }
    }
    banner: datoCmsGlobal {
      challengesBanner {
        gatsbyImageData(
          imgixParams: { w: "1280", h: "640", fit: "crop", auto: "true" }
        )
        url(imgixParams: { w: "1000", fit: "max", fm: "jpg" })
      }
    }
    content: contentYaml(language: { eq: "en-NZ" }) {
      challenges {
        content
        meta {
          title
          desc
        }
      }
      tabs {
        challenges {
          anytime
          current
          coming
        }
      }
    }
    seo: datoCmsSite {
      ...GlobalSeo
    }
    groups: allDatoCmsChallengeGroup(filter: { locale: { eq: $language } }) {
      nodes {
        id
        slug
        title
        startDate(formatString: "dddd do MMM")
        duration
        anytimeChallenge
        banner {
          gatsbyImageData(imgixParams: { w: "770", h: "460", fit: "crop" })
        }
        path
        internal {
          type
        }
        meta {
          firstPublishedAt
        }
      }
    }
    miscContent: datoCmsMiscContent {
      challengesText
      challengesButton {
        link {
          ...LinkHelper
        }
      }
    }
  }
`
