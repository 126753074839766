import React from "react"
import style from "./cardsContainer.mod.scss"

const CardsWrapper = props => {
  const {
    children,
    modifier,
    orientation,
    columns,
    size,
    desktopScroll = false
  } = props

  const classes = [style.cards]
  modifier && classes.push(`${style.cards}--${modifier}`)
  desktopScroll && classes.push(`${style.cards}--desktop-scroll`)
  orientation && classes.push(`${style.cards}--${orientation}`)
  orientation &&
    columns &&
    classes.push(`${style.cards}--${orientation}-${columns}-col`)
  orientation &&
    columns &&
    size &&
    classes.push(`${style.cards}--${orientation}-${columns}-col-for-${size}`)
  columns && size && classes.push(`${style.cards}--${columns}-col-for-${size}`)

  return (
    <div className={classes.join(" ")}>
      <div className={style.cards__inner}>
        <ul className={style.cards__list}>{children}</ul>
      </div>
    </div>
  )
}

export default CardsWrapper
